<script setup>
import useAuthStore from '../stores/authStore';

const authStore = useAuthStore();

</script>

<template>
  <div class="header">
    <div class="header__logo">
      <img src="../assets/logo.png" alt="DocInWay">
    </div>
    <div v-if="authStore.user">
      <input id="menu-toggle" type="checkbox" />
      <label class='menu-button-container' for="menu-toggle">
        <div class='menu-button'></div>
      </label>
      <ul class="menu">
        <li>
          <router-link :to="{ name: 'Admin' }" class="menu__item">Dashboard</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Form' }" class="menu__item">Invertir</router-link>
        </li>
        <li>
          <a class="menu__item" href="#" @click="authStore.logout()">Cerrar Sesión</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/components/header.scss";
</style>
