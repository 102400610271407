<script>
import CircleProgress from '@/components/CircleProgress.vue';

export default {
  name: 'Home',
  components: {
    CircleProgress,
  },
  setup() {
    return {
      medias: [
        'QznhmMS3ye8',
        'gIRU9OOK2MM',
        'MQ3onFXk8Bc',
        'EV42d_OA-J0',
        'xsHVCP9btzU',
        'fffzO4l_VF8',
        '_iwNkm91Q5I',
        'eAZuAjr6Jik',
        'UrMFp-mygsM',
        'fbhy1DIiphQ',
        'KdrUsX9ccwM',
        '2UN2W4yX6Ic',
        'Cl45HWknVKw',
        'gmqtUBJIDUE',
        'jOpfZYsaiVQ',
      ],
    };
  },
};
</script>

<template>
  <div class="home">
    <div class="content content-even home__content">
      <div class="home__content__row">
        <div class="home__content__row__column-6">
          <iframe class="home__video" width="auto" height="100%" src="https://www.youtube.com/embed/QznhmMS3ye8?t=61s"
            frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
        <div class="home__content__row__column-6">
          <CircleProgress />
        </div>
      </div>
    </div>

    <div class="content content-odd home__content">
      <h1 class="home__content__title">Background</h1>
      <p>
        DocInWay es un sistema para prevenir enfermedades o padecimientos secundarios por medio de
        inteligencia artificial, algoritmos y la asesoría de médicos certificados; creando una
        inteligencia colectiva.
      </p>
      <p>
        El paciente ingresa sus datos en el historial médico, síntomas, estudios y tratamientos; con
        el objetivo de que en el futuro, nuestra inteligencia artificial detecte los factores de
        riesgo, pudiendo cambiar hábitos o agendar una consulta con un médico especialista.
      </p>
      <p>
        Los profesionales de la salud se suscriben a la DocInWay Partners para llegar a más
        pacientes, pertenecer a una comunidad y aprender de sus colegas, además de facilitarles la
        organización de su agenda, expedientes médicos y cobros; buscamos conocer su trayectoria y
        certificaciones, para optimizar el tiempo de diagnóstico y tratamiento que recibirá el
        paciente.
      </p>
      <div class="home__content__image">
        <img src="../assets/comunidad.jpg" alt="Comunidad" />
      </div>
      <p>Otras funciones habilitadas:</p>
      <ul>
        <li>Recordatorios de medicamentos.</li>
        <li>Adherencia de tratamiento.</li>
        <li>Historial de consultas.</li>
        <li>Próximas citas.</li>
      </ul>
      <p>
        DocInWay tiene más de 8 mil usuarios, entre pacientes y profesionales de la salud, gracias a
        las alianzas que hemos concretados con algunas fundaciones, como:
      </p>
      <ul>
        <li>
          <b>Fundación Teletón México:</b> Cuenta con 22 centros de rehabilitación, un centro de
          autismo y un hospital oncológico.
        </li>
        <li>
          <b>APAC:</b> Atención educativa y rehabilitación física a personas con lesión cerebral.
        </li>
        <li>
          <b>Federación Mexicana de Enfermedades Raras:</b> Padecimientos de baja incidencia y con
          poca atención
        </li>
        <li><b>Fundación Interamericana del Corazón:</b> Deficiencias cardiacas.</li>
        <li><b>Angelitos de Cristal:</b> Ostegénesis Imperfecta</li>
        <li><b>Ampuvalia:</b> Provisión de prótesis.</li>
        <li><b>Vuelo Libre:</b> Inclusión laboral de personas con discapacidad.</li>
      </ul>
    </div>
    <div class="content content-even home__content">
      <h1 class="home__content__title">COVID-19</h1>
      <p>
        La pandemia ocasionada por el Coronavirus (COVID-19) ocasionó que la atención médica
        evolucionara drásticamente, por tal motivo se implementarán algunos módulos, para que los
        pacientes tengan un mayor control de su bienestar físico y emocional, como:
      </p>
      <p>
        <b>Telemedicina:</b> Atención médica a distancia mediante videollamada, sin embargo es
        necesario tener datos médicos básicos; como signos vitales, para poder realizar una consulta
        de calidad.
      </p>
      <p>
        Por tal motivo, se incluirá tecnología para medir la saturación de oxígeno, frecuencia
        cardiaca, frecuencia respiratoria, etc.
      </p>
      <div class="home__content__image">
        <img src="../assets/telemedicina.jpg" alt="Comunidad" />
      </div>
      <p>
        <b>Consulta / Terapias a Domicilio:</b> Muchas personas tienen algún tipo de condición que
        requiere de extremos cuidados para evitar contagiarse de COVID-19, por tal motivo es más
        seguro que reciban el tratamiento en su hogar.
      </p>
      <div class="home__content__image">
        <img src="../assets/consulta_domicilio.jpg" alt="Consulta a domicilio∫" />
      </div>
    </div>
    <div class="content content-odd home__content">
      <h1 class="home__content__title">DocInWay B2B</h1>
      <p>
        En los últimos años, las empresas han demostrado un interes en el binestar de sus
        colaboradores, lo que tiene como consecuencia el aumento de la productividad, por tal motivo
        DocInWay tendrá un nuevo desarrollo, en donde se buscara impactar a las personas mediante un
        control con las especialidades médicas más visitadas, entre las que destacán
        Gastroenterología, Ginecología, Odontología, Nutriología y Psicología.
      </p>
    </div>
    <div class="content content-even home__content">
      <h1 class="home__content__title">DocInWay Foundation</h1>
      <p>
        La crisis economica y de desempleo ha ocacionado que millones de personas no puedan acceder
        a una atención médica de calidad; por tal razon, creamos una una fundación en donde podamos
        obtener donativos (dedicibles de impuestos) para apoyar a la sociedad con:
      </p>
      <ul>
        <li>Consultas</li>
        <li>Terapias</li>
        <li>Medicamentos</li>
        <li>Dispositivos</li>
        <li>Prótesis</li>
        <li>Cirugías</li>
      </ul>
    </div>
    <div class="content content-odd home__content">
      <h1 class="home__content__title">Mercado</h1>
      <p>
        Los seres humanos sufren de enfermedades debido a su genética, el medio ambiente en el que
        se encuentra y su estilo de vida; la sociedad mexicana está constituida por alrededor de 125
        millones de habitantes, de las cuales el 89% usan smartphone, haciendo posible que DocInWay
        llegue a más de 100 millones de personas.
      </p>
      <p>
        Los early adopters son personas con algún padecimiento crónico, genético o discapacidad,
        este último segmento de la población asciende al 10% de la sociedad mexicana.
      </p>
      <p>
        En lo referente al sector salud, en México hay 531 mil profesionales, considerando a
        médicos, odontólogos, nutriólogos, psicólogos y terapeutas; para cubrir las necesidades de
        un país que cuenta con los índices más altos en obesidad y diabetes.
      </p>
      <p>El público objetivo se divide de la siguiente manera:</p>
      <ul class="home__content__list-without-style">
        <li>
          <b>Pacientes:</b> Personas con alguna enfermedad crónica, genética o discapacidad, los
          cuales deben llevar un control de su tratamiento consultas y estudios.
        </li>
        <li>
          <b>Personas que cuidan su calidad de vida:</b> Con un estricto control de su alimentación,
          cuidados médicos, actividad física.
        </li>
        <li><b>El número de Profesionales de la Salud en México asciende a mas de 700 mil</b></li>
        <li>
          <div class="home__content__image">
            <img src="../assets/grafica_mercado_B2C.png" alt="Grafica de mercado B2C" />
          </div>
        </li>
        <li>
          <b>Cliente empresarial:</b> De tamaño mediano a a grande, que brinden seguro de gastos
          médicos mayores a sus colaboradores y quieran implementar la NOM-0035.
        </li>
        <li>
          <b>Fundaciones:</b> Al depender de donativos para seguir operando y brindando beneficios a
          grupos vulnerables, la automatización de procesos y la implementación de tecnología se ve
          rezagada, DocInWay puede ser el vehículo para que optimicen los recursos y lleguen a más
          personas, al mismo tiempo que nosotros generamos usuarios y perfeccionando nuestros
          algoritmos de concordancia.
        </li>
        <li>
          <b>Hospitales:</b> Lograr que los profesionales de la salud que laboren en los hospitales
          se registren en DocInWay, con el objetivo de tener una mayor variedad de especialidades.
        </li>
        <li>
          <b>Escuelas de Medicina / Terapeutas:</b> Aliarse con estas instituciones para que los
          recién egresados puedan iniciar su trayectoria laboral con el uso de DocInWay.
        </li>
      </ul>
    </div>
    <div class="content content-even home__content">
      <h1 class="home__content__title">Modelo de Negocios</h1>
      <p>
        DocInWay se comercializa como SAAS (Software As A Service), teniendo como consecuencia el
        ingreso en forma de suscripción mensual, la cual absorben los profesionales de la salud y
        dependiendo el módulo que eligen será el monto de la suscripción:
      </p>
      <div>
        <div>Consultorio Inteligente - $190 MXN</div>
        <div>Telemedicina - $790 MXN</div>
        <div>Consultas en Domicilio - $990 MXN</div>
        <div class="text-center">
          <a href="https://drive.google.com/file/d/1MZ39E81-cBoj1QwvMY2Om9oMxQlqM4tu/view?usp=sharing" target="_blank"
            class="actions__button">Proyecciones de Ingresos</a>
        </div>
      </div>
      <p>
        Para los pacientes es gratuito, sin embargo habrá servicios especiales que tendrán una cuota
        de recuperación, aún se esta validando la tecnología de estos módulos.
      </p>
      <p>
        En lo referente al modelo B2B, se buscará a organizaciones con Responsabilidad Social
        Empresarial, que se preocupen por sus colaboradores e implementen la NOM-0035, enfocada a
        salud mental, ofreciendo servicios de prevención de algunas especialidades y tener un
        ingreso dependiendo el número de colaboradores.
      </p>
    </div>
    <div class="content content-odd home__content">
      <h1 class="home__content__title">Inversión</h1>
      <p>
        La inversión se utilizará para cubrir diversas áreas, hasta alcanzar el punto de equilibrio,
        que será entre el mes 6 y 12.
      </p>
      <ul>
        <li>Capital de Trabajo: $600,000 MXN</li>
        <li>Marketing: $900,000 MXN</li>
        <li>
          Investigación, Desarrollo y gastos recurrentes de Tecnológia (Infraestructura) : $100,000
          MXN
        </li>
        <li>Relaciones Públicas: $100,000 MXN</li>
        <li>Servicios Fiscales y Legales: $100,000 MXN</li>
        <li>
          Seguridad Informatica: $200,000 MXN (al manejar datos sensibles debemos implentar varios
          metodos de cifrado)
        </li>
      </ul>
      <div class="text-center">
        <a class="actions__button"
          href="https://www.eleconomista.com.mx/tecnologia/2020-en-12-hackeos-o-incidentes-de-seguridad-en-Mexico-20210102-0007.html"
          target="_blank">2020, en 12 hackeos o incidentes de seguridad en México</a>
      </div>
      <div class="home__content__image">
        <img src="../assets/grafica_inversion.png" alt="Grafica de mercado B2C" />
      </div>
    </div>
    <div class="content content-even home__content">
      <h1 class="home__content__title">Retorno de Inversión</h1>
      <p>
        Al ser una empresa en etapa temprana la inversión es a mediano o largo plazo, pero hay
        cuatro formas de tener un retorno de inversión.
      </p>
      <p>
        <b>Porcentaje de la empresa (Equity):</b> Eres dueño de un porcentaje de la organización, y
        conforme está aumente su valor tu porcentaje también lo hará, pudiendo vender tus
        participación a un costo mayor.
      </p>
      <p>DocInWay ha aumentado su valor 4.2X.</p>
      <div>Valuación 2017 - 4.3 MDP</div>
      <div>Valuación 2021 – 18.3 MDP</div>
      <p>
        <b>Reparto de utilidades:</b> Al tener utilidades se debe de repartir un porcentaje de estas
        de forma anual, sin embargo hasta no alcanzar el punto de equilibrio y tener un flujo
        constante de efectivo; no se puede realizar, ya que las ganancias se reinvierten.
      </p>
      <p>
        <b>Compra de empresa:</b> Al lograr tener una facturación constante y/o un porcentaje del
        mercado, otras empresas dentro del sector buscarán adquirir la infraestructura, operaciones
        y clientes; ya sea para aumentar su capacidad, desarrollar nuevos módelos de negocio o para
        eliminar a una posible competencia.
      </p>
      <p>
        <b>IPO (Inicial Public Offering):</b> Cuando una empresa se vuelve pública y sus acciones la
        puede adquirir cualquier persona en el mercado bursátil, para lograr esto se debe tener
        presencia en varios países y ser un referente en la industria.
      </p>
    </div>
    <div class="content content-odd home__content">
      <h1 class="home__content__title">Tracción</h1>
      <p>DocInWay ha tenido grandes logros como:</p>
      <ul>
        <li>
          Ser seleccionados por la Universidad Panamericana para formar parte de su tercera
          generación de startups en aceleración.
        </li>
        <li>
          Tener acercamiento con Farmacéuticas y Aseguradoras para usar DocInWay en su cadena de
          suministros.
        </li>
        <li>
          La revista de negocios Entrepreneur, en conjunto con Facebook México, nos catalogó como
          uno de los 20 proyectos con más impacto social del 2019.
        </li>
        <li>
          Google México quiere invertir capital humano para eficientizar las campañas de marketing y
          poder impactar a más personas.
        </li>
        <li>Ser semifinalistas del programa POSIBLE, impulsado por Fundación Televisa.</li>
      </ul>
      <div class="home__content__images-traccion">
        <img src="../assets/entrepreneur.png" alt="Entrepreneur" />
        <img src="../assets/up.png" alt="Universidad Panamericana" />
        <img src="../assets/posible.png" alt="Posíble" />
        <img src="../assets/google.png" alt="Google" />
      </div>
    </div>
    <div class="content content-even home__content">
      <h1 class="home__content__title">Media</h1>
      <div class="home__content__row">
        <div class="home__content__row__column-4" v-for="(media, $index) in medias" :key="$index">
          <iframe width="100%" height="100%" title="YouTube video player"
            :src="`https://www.youtube.com/embed/${media}`" frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/views/home.scss";
</style>
