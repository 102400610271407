import axios from 'axios';
import { API_URL } from '../../config';

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  // 'Access-Control-Allow-Origin': '*',
};

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers,
});

export default axiosInstance;
