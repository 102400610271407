import { defineStore } from 'pinia';
import SessionService from '../services/SessionService';
import AuthService from '../services/AuthService';

const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    user: SessionService.getCurrentSession(),
    returnRouteName: null,
  }),
  actions: {
    replaceUser(user) {
      this.user = { ...this.user, user };
      SessionService.createSession(this.user);
    },
    async login(email, password) {
      try {
        const user = await AuthService.login(email, password);
        console.log(user);
        this.user = user;
        SessionService.createSession(user);
        this.router.push({ name: this.returnRouteName || 'Admin' });
      } catch (err) {
        alert(err);
      }
    },
    async register(data) {
      try {
        const user = await AuthService.register(data);
        this.user = user;
        SessionService.createSession(user);
        this.router.push({ name: this.returnRouteName || 'Welcome' });
      } catch (error) {
        let msg = error;
        console.log(error);
        const { response } = error;
        if (response.data.errors) {
          const firstKey = Object.keys(response.data.errors[0])[0];
          msg = response.data.errors[0][firstKey].message;
        }
        alert(msg);
      }
    },
    logout() {
      this.user = null;
      SessionService.destroySession();
      this.router.push({ name: this.returnRouteName || 'Welcome' });
    },
  },
});

export default useAuthStore;
