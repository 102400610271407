const KEY_SESSION = '___KEY_SESSION___';

class SessionService {
  static getCurrentSession() {
    const retrievedObject = window.localStorage.getItem(KEY_SESSION);
    return retrievedObject !== 'undefined' && JSON.parse(retrievedObject) ? JSON.parse(retrievedObject) : null;
  }

  static destroySession() {
    window.localStorage.removeItem(KEY_SESSION);
  }

  static createSession(data) {
    if (data?.token) {
      window.localStorage.setItem(KEY_SESSION, JSON.stringify(data));
    } else {
      this.destroySession();
    }
  }

  static existSession() {
    return this.getCurrentSession() !== null;
  }

  static getAccessToken() {
    return this.getCurrentSession()?.token;
  }
}

export default SessionService;
