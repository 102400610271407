<template>
  <div class="login">
    <h2 class="margin-bottom-2">Bienvenido</h2>
    <h2 class="margin-bottom-2">Ya eres socio de DocInWay</h2>
    <div>
      <img src="../assets/logo_vertical.png" alt="Logo">
    </div>
    <h2>
      Si te interesa ser socio de la empresa, solo debes dar click aquí
    </h2>
    <router-link :to="{ name: 'Form' }" class="btn-lg actions__button margin-top-2">Invertir</router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/views/login.scss";
</style>
