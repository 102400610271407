<script>
import { ref, reactive, computed } from 'vue';
import numeral from 'numeral';
import { GChart } from 'vue-google-charts';
import useVuelidate from '@vuelidate/core';
import {
  required,
  minLength,
  email as emailValidation,
} from '@vuelidate/validators';
import useAuthStore from '../stores/authStore';

export default {
  name: 'CircleProgress',
  components: {
    GChart,
  },
  setup() {
    const authStore = useAuthStore();
    const loading = ref(false);

    const profile = reactive({
      fullname: '',
      phone: '',
      email: '',
      password: '',
    });

    const rulesProfile = computed(() => ({
      fullname: { required },
      phone: {},
      email: { required, email: emailValidation },
      password: {
        required,
        minLength: minLength(8),
      },
    }));

    const v$ = useVuelidate(rulesProfile, profile);
    v$.value.$touch();

    const suscribe = async () => {
      loading.value = true;
      await authStore.register({
        ...profile,
      });
      loading.value = false;
    };

    return {
      numeral,
      chartData: [
        ['Inv', '%'],
        ['Total', 500000],
        ['Pendiente', 900000],
      ],
      chartOptions: {
        pieHole: 0.7,
        backgroundColor: '#f2f2f2',
        pieSliceTextStyle: {
          color: '#025494',
          fontSize: '10px',
        },
        slices: {
          0: { color: '#269BFF' },
          1: { color: 'white' },
        },
        tooltip: {
          textStyle: {
            color: '#025494',
            fontSize: '6px',
          },
          text: 'value',
          ignoreBounds: false,
        },
        legend: 'none',
        pieStartAngle: 180,
      },
      v$,
      suscribe,
      authStore,
      loading,
    };
  },
};
</script>

<template>
  <GChart type="PieChart" :data="chartData" :options="chartOptions" style="font-size:auto" />
  <div class="inversion" v-if="!authStore.user">
    <div>
      <p>
        DocInWay ha tenido un crecimiento exponencial y en conjunto con todo Inclusion A. C. impactará a más personas.
        <br>
        ¿Quieres recibir mas informacion? Registrate aquí:
      </p>
      <div class="">
        <div class="form__content__label">
          <label class="">Nombre Completo</label>
          <input class="form-control" type="text" v-model="v$.fullname.$model" />
          <span class="form__content__label-errors" v-if="v$.fullname.$error">
            {{ v$.fullname.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label class="">Teléfono</label>
          <input class="form-control" type="text" v-model="v$.phone.$model" />
        </div>
        <div class="form__content__label">
          <label class="">Correo Electrónico</label>
          <input class="form-control" type="text" v-model="v$.email.$model" autocomplete="off" />
          <span class="form__content__label-errors" v-if="v$.email.$error">
            {{ v$.email.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
        <div class="form__content__label">
          <label class="">Contraseña</label>
          <input class="form-control" type="password" v-model="v$.password.$model" autocomplete="new-password" />
          <span class="form__content__label-errors" v-if="v$.password.$error">
            {{ v$.password.$errors.map(({ $message }) => $message).join(', ') }}
          </span>
        </div>
      </div>
      <div class="form form-loader" v-if="loading">
        <div class="text-center">
          <h2>Estamos procesando tu petición no cierres la pestaña...</h2>
        </div>
      </div>
    </div>
    <div class="actions">
      <button class="actions__button" @click.prevent="suscribe" :disabled="v$.$invalid">Registrarte</button>
    </div>
  </div>
  <div class="inversion" v-if="authStore.user">
    <p>
      Bienvenido {{ authStore.user.fullname }}
    </p>
    <div class="cta-btns">
      <router-link :to="{ name: 'Admin' }" class="btn-lg actions__button margin-top-2">Dashboard</router-link>
      <router-link :to="{ name: 'Form' }" class="btn-lg actions__button margin-top-2">Invertir</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/components/circle-progress.scss";
@import "@/scss/views/form.scss";
</style>
