import api from './api/api';

class AuthService {
  static async login(email, password) {
    const res = await api.post('crowdfunding/login', { email, password });
    return res.data;
  }

  static async register(data) {
    const res = await api.post('crowdfunding/partner/create', data);
    return res.data;
  }
}

export default AuthService;
